<template>
  <div v-if="show">
    <header>
      <nav class="navbar header-nav navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="index.html"
            ><img src="img/logo.png" height="100" alt="image"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarApp"
            aria-controls="navbarApp"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarApp"
          >
            <ul class="navbar-nav">
              <li>
                <a class="nav-link active" href="#/home?show=service"
                  >SUPer Card服務</a
                >
              </li>
              <li><a class="nav-link" href="#/home?show=plan">會員計劃</a></li>
              <li><a class="nav-link" href="#/home?show=offer">優惠</a></li>
              <li><a class="nav-link" href="#/home?show=shop">線上線下服務</a></li>
              <li>
                <a class="nav-link" href="#/home?show=partner">合作商戶</a>
              </li>
              <li>
                <a class="nav-link" href="#/home?show=contact">聯絡我們</a>
              </li>
              <li id="sso-button" v-if="!userInfo.id">
                <a class="nav-link">登入</a>
              </li>
              <li v-if="userInfo.id">
                <a class="nav-link" @click="goToUserInfo">資料管理</a>
              </li>
              <li v-if="userInfo.id" class="user nav-link">
                <img
                  :src="userInfo.headimgurl"
                  class="user_img"
                  v-if="userInfo.headimgurl"
                />
                <img
                  src="@/assets/user.png"
                  class="user_img"
                  v-if="!userInfo.headimgurl"
                />
                <div class="nickname">{{ userInfo.nickname }}</div>
              </li>
              <li v-if="userInfo.id" class="user">
                <a class="nav-link" @click="out">登出</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <template v-if="show">
      <div id="testimonials" class="section wb">
        <div class="container">
          <div class="section-title text-left">
            <h3>SUPer Card會員計劃 ─ 條款及細則</h3>
            <h2>
              聯合新零售(香港)有限公司
              (以下簡稱「本公司」)尊重使用者的私隱權，並承諾遵守《個人資料(私隱)條例》的規定，盡力保障本公司所持有的個人資料的私隱，保密性及安全。本公司同樣承諾確保本公司所有員工及代理堅守這些責任。請詳細閱讀本條款以了解「SUPer
              Card會員計劃」收集、應用及保護閣下所提供的資料詳情及守則。當使用者瀏覽此網站或使用相關服務，代表使用者接受及同意此私隱條款。本條款可能會隨時變更，請定期查閱。
            </h2>
          </div>
          <!-- end title -->

          <div class="content">
            <h2>1) 有關「SUPer Card會員」會籍</h2>
            <p>
              1.1 透過註冊SUPer
              Card會員，即表示使用者聲明(而本公司有權根據此依賴有關聲明)使用者必須年滿18歲或以上，並有構成具法律成具法律約束合約的能力。如使用者的年齡未滿十八歲而希望成為會員，必須事前獲得父母或監護人同意，並依此符合本條款。
            </p>
            <p>1.2 合作商戶</p>
            <p>- 聯合新零售(香港)有限公司</p>
            <p>- 新雅文化事業有限公司</p>
            <p>- 三聯書店(香港)有限公司</p>
            <p>- 商務印書館(香港)有限公司</p>

            <p>1.3 服務</p>
            <p>- 「一本」(www.mybookone.com.hk)</p>
            <p>- 「新雅書迷會」(https://www.sunyabookclub.com.hk)</p>
            <p>- 「三聯讀書會」</p>
            <p>- 「Bookaholic」</p>
            <p>
              1.4 用戶必須通過本公司或合作商戶之服務提供的登記方法登記成為SUPer
              Card會員，所有SUPer
              Card會員必須通過符合本計劃使用之流動電話號碼或電子郵件帳號進行有效登記，於使用部份服務時(如：網上購物)會員必須提供有效電子郵件帳號。唯未經本公司處理及認可之登記，均視為未經確認之會籍。
            </p>
            <p>
              1.5 本公司擁有批核會籍之最終決定權，並可拒絕任何用戶成為SUPer
              Card會員。
            </p>
            <p>
              1.6 SUPer Card會員會籍由成功登記當天起生效，SUPer
              Card會員即可通過統一登入名稱及密碼，登入列明合作商戶之服務。日後如有合作商戶加入此計劃，SUPer
              Card會員亦可通過該SUPer Card會員身份登入並使用該合作商戶的服務。
            </p>
            <p>
              1.7 如通過SUPer
              Card會員登入合作商戶之服務，即代表同意成為該合作商戶之會員，有關細則請留意相關服務條款。
            </p>
            <p>
              1.8 本公司會不時組織合作商戶舉辦活動及優惠，並向SUPer
              Card會員發放資訊。
            </p>
            <p>
              1.9 合作商戶只會通過其服務向SUPer Card會員提供其服務之優惠及訊息。
            </p>
            <p>
              1.10
              合作商戶將通過其服務提供不同優惠、折扣及獎賞，所有優惠、折扣及獎賞由合作商戶提供，有關使用及權責由相關合作商戶負責。
            </p>
            <p>
              1.11
              合作商戶之會籍級別、累計消費金額及積分，將根據該合作商戶獨立計算及提供。除另有說明，否則有關會籍級別、累計消費金額及積分只可以在同一合作商戶內使用，不可轉移，有關細則請留意相關服務條款。
            </p>
            <p>
              1.12 合作商戶將不時通過其服務為SUPer
              Card會員提供跨平台的消費獎勵。
            </p>
            <p>1.13 SUPer Card會員會籍不可轉借他人使用。</p>
            <p>
              1.14 就本計劃使用個人資料及相關私隱條款的詳情，請詳細閱讀「SUPer
              Card會員計劃 -
              私隱條款聲明」(http://www.supercard.com.hk/privacyandpolicy.html
              )。
            </p>
            <h2>2) 服務終止</h2>
            <p>
              2.1 SUPer
              Card會員有權隨時終止使用本公司提供的一切服務，及要求刪除所提供的個人資料，本公司在收到有關通知後的60天內，終止有關服務。除非法律規定要求本公司須保存該SUPer
              Card會員的個人資料⼀段特定的時間，本公司只會將其個人資料保存至達到收集個人資料之原來目的，或直接與其有關之目的為⽌，並會隨後銷毀該等資料。
            </p>
            <p>
              2.2 本公司保留終止SUPer Card會員和刪除帳戶資訊的權利。若SUPer
              Card會員未能遵守本條款及細則，本公司有權在無需給予該會員任何事先通知的情況下即時取消其會籍資格及權利。
            </p>
            <p>
              2.3 於會籍的終止日期當天，該SUPer Card會員的所有會籍權利將被取消。
            </p>
            <p>
              2.4
              如本公司終止本計劃，將於六個月前於本計劃網址及合作商戶服務提前通知。
            </p>
            <h2>3) 不可抗力</h2>
            <p>
              3.1
              如本公司因一些超出本公司合理控制範圍以外之事件而未能執行或延遲執行本條款及細則所規定的義務，本公司將不會向SUPer
              Card會員或任何第三者承擔任何責任。其中可能包括但不限於罷工、火災、洪水、暴雨、天災、傳染病疫情、戰爭、政府措施、勞工情況、社會活動、互聯網失靈或電訊供應商經歷的反常連接問題。一旦發生不可抗力事件，本公司並無義務在不可抗力事件持續時及在受不可抗力影響下履行我們的責任。
            </p>
            <h2>4) 其他</h2>
            <p>
              4.1
              本公司保留隨時修改會籍權益、會籍資格、合作商戶、服務及使用條款之權利等，及不作另行通知，請定期查閱相關條款。本計劃如有更改，本公司將會於網頁www.supercard.com.hk
              公佈。
            </p>
            <p>4.2 如有任何爭議，聯合新零售(香港)有限公司保留最終決定權。</p>
            <p>
              4.3
              本條款及細則受香港特別行政區法律規管，並按照香港特別行政區法律予以解釋。
            </p>

            <h3>此版本最後更新日期：2021年7月12日</h3>
          </div>
        </div>
      </div>

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <img src="img/logo2.png" alt="" height="100" />
                </div>
                <p>提供不同文化閱讀資訊、購物折扣、獎賞及優惠。</p>
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <h3>條款及細則</h3>
                </div>
                <ul class="footer-links">
                  <li><a href="#/termsandconditions">條款及細則</a></li>
                  <li><a href="#/privacyandpolicy">私隱條款聲明</a></li>
                </ul>
                <!-- end links -->
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <h3>聯絡我們</h3>
                </div>
                <ul class="footer-links">
                  <li>
                    <a href="mailto:cs@supercard.com.hk">cs@supercard.com.hk</a>
                  </li>
                  <li>
                    <a href="http://www.supercard.com.hk"
                      >www.supercard.com.hk</a
                    >
                  </li>
                </ul>
                <!-- end links -->
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
        <!-- end container -->
      </footer>
      <!-- end footer -->

      <div class="copyrights">
        <div class="container">
          <div class="footer-distributed">
            <div class="footer-left">
              <p class="footer-company-name">
                Copyright © SUP Retail (Hong Kong) Limited &nbsp;&nbsp; All
                Rights Reserved. <br />版權全屬 聯合新零售(香港)有限公司
              </p>
            </div>
          </div>
        </div>
        <!-- end container -->
      </div>
      <!-- end copyrights -->
    </template>
    <a href="#top" data-scroll class="dmtop global-radius"
      ><i class="fa fa-angle-up"></i
    ></a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    userInfo: function () {
      return this.$store.state.appData.userInfo;
    },
  },
  methods: {
    goToUserInfo() {
      oauthSDK.goToPage("userData");
    },
    out() {
      oauthSDK
        .ssoLogout({ membersession: this.$Cookies.get("memberSessionSSO") })
        .then(() => {
          location.reload();
        })
        .catch((err) => {});
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show = true;
      }, 100);
    });
    $("#default").zenith({
      layout: "default",
      slideSpeed: 450,
      autoplaySpeed: 2000,
    });
  },
};
</script>
<style scoped>
.user {
  display: flex;
  align-items: center;
}
.user_img {
  height: 30px;
  width: 30px;
  border-radius: 60px;
  margin-right: 5px;
}
.nickname {
}
.navbar-nav {
    color: #1f1f1f !important;
}
</style>