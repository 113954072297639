import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import loadScript from "./utils/loadScript.js";
Vue.prototype.$loadScript = loadScript;
import Cookies from "vue-cookies";
// Vue.use(Cookies)
Vue.prototype.$Cookies=Cookies
import store from "./store/index";

Vue.mixin({
  data() {
    return {};
  },
  methods: {
  
  },
  mounted() {
  },
});

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  },
}).$mount("#app");
