<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  components: {},
  methods: {
    getSSO() {
      oauthSDK
        .getUserInfo()
        .then((e) => {
          this.$store.commit("userInfo", e);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    loadScript(clientType) {
      let t=new Date().getTime() 
      this.$loadScript(process.env.VUE_APP_ssoSDK+"?time="+t, () => {
        this.$store.commit("upDatassoSDK", true);
        oauthSDK.init({
          id: "sso-button", // 跳轉登錄頁面
          appid: process.env.VUE_APP_ssoAppid, // 請求自帶
          // bsKey: process.env.VUE_APP_ssoBsKey,
          bsKey: "",
          clientType: clientType,
          redirectURL: process.env.VUE_APP_redirectURL,
          logoutURL:process.env.VUE_APP_logoutURL,
        });
        if (this.$Cookies.get("memberSessionSSO")) {
          this.getSSO();
        }
      });
    },
  },
  mounted() {
    var w = window.innerWidth;
    window.onresize = () => {
      // if (w > window.innerWidth) {
        let clientType = window.innerWidth > 990 ? "web" : "wap";
        oauthSDK.init({
          id: "sso-button", // 跳轉登錄頁面
          appid: process.env.VUE_APP_ssoAppid, // 請求自帶
          bsKey: process.env.VUE_APP_ssoBsKey,
          clientType: clientType,
          redirectURL: process.env.VUE_APP_redirectURL,
        });
      // }
    };
    let clientType = window.innerWidth > 990 ? "web" : "wap";
    this.loadScript(clientType);
  },
};
</script>
<style>
html,
body {
  scroll-behavior: smooth;
}
a {
  cursor: pointer;

}
#app {
  width: 100%;
  background: #f6f6f6;
  box-sizing: border-box;
}
header {
  background: #fff;
}
* {
  margin: 0;
  padding: 0;
  /* font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
    "Microsoft YaHei", "微软雅黑", Arial, sans-serif; */
}
</style>
