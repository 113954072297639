import Vue from "vue";
import VueRouter from "vue-router";
import home from "../views/Home.vue";
import privacyandpolicy from "../views/privacyandpolicy.vue";
import termsandconditions from "../views/termsandconditions.vue";
import sso from "../views/sso.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    name: "home",
    component: home,
  },
  {
    path: "/home",
    name: "home",
    component: home,
  },
  {
    path: "/privacyandpolicy",
    name: "privacyandpolicy",
    component: privacyandpolicy,
  },
  {
    path: "/termsandconditions",
    name: "termsandconditions",
    component: termsandconditions,
  },
  {
    path: "/sso",
    name: "sso",
    component: sso,
  },
];
const router = new VueRouter({
  routes,
});

export default router;
