import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
const state = {
  ssoSDK: false,
  userInfo: {},
};
const getters = {};
const mutations = {
  upDatassoSDK(state, data) {
    state.ssoSDK = data;
  },
  userInfo(state, data) {
    state.userInfo = data;
  },
};
const actions = {
  //自定义触发mutations里函数的方法，context与store 实例具有相同方法和属性
};

export default {
  state,
  getters,
  actions,
  mutations,
};
