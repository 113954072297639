/**
 * 异步加载JS，在JS加载完成后，执行callback
 * @param {String} url
 * @param {Function} callback
 */
 function loadScript(url, callback, attrObj) {
    var script = document.createElement("script");
    script.type = "text/javascript";
    if (attrObj) {
      for (const attrName in attrObj) {
        script.setAttribute(attrName, attrObj[attrName]);
      }
    }
    if (script.readyState) {
      script.onreadystatechange = function() {
        if (script.readyState == "complete" || script.readyState == "loaded") {
          callback();
        }
      };
    } else {
      script.onload = function() {
        callback();
      };
    }
    script.src = url; //开始异步下载的js文件
    document.head.appendChild(script);
  }
  
  export default loadScript