<template>
  <div>
    <header>
      <nav class="navbar header-nav navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="index.html"
            ><img src="img/logo.png" height="100" alt="image"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarApp"
            aria-controls="navbarApp"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarApp"
          >
            <ul class="navbar-nav">
              <li>
                <a
                  class="nav-link active"
                  @click.prevent="custormanchor('service')"
                  >SUPer Card服務</a
                >
              </li>
              <!-- <li><a class="nav-link" href="#plan">會員計劃</a></li>
              <li><a class="nav-link" href="#offer">優惠</a></li>
              <li><a class="nav-link" href="#shop">門市書店</a></li>
              <li><a class="nav-link" href="#partner">合作商戶</a></li>
              <li><a class="nav-link" href="#contact">聯絡我們</a></li> -->
              <li>
                <a class="nav-link" @click.prevent="custormanchor('plan')"
                  >會員計劃</a
                >
              </li>
              <li>
                <a class="nav-link" @click.prevent="custormanchor('offer')"
                  >優惠</a
                >
              </li>
              <li>
                <a class="nav-link" @click.prevent="custormanchor('shop')"
                  >線上線下服務</a
                >
              </li>
              <li>
                <a class="nav-link" @click.prevent="custormanchor('partner')"
                  >合作商戶</a
                >
              </li>
              <li>
                <a class="nav-link" @click.prevent="custormanchor('contact')"
                  >聯絡我們</a
                >
              </li>
              <li id="sso-button" v-if="!userInfo.id">
                <a class="nav-link">登入</a>
              </li>
              <li v-if="userInfo.id">
                <a class="nav-link" @click="goToUserInfo">資料管理</a>
              </li>
              <li v-if="userInfo.id" class="user nav-link">
                <img :src="userInfo.headimgurl" class="user_img" v-if="userInfo.headimgurl" />
                <img src="@/assets/user.png" class="user_img" v-if="!userInfo.headimgurl" />
                <div class="nickname">{{ userInfo.nickname }}</div>
              </li>
              <li v-if="userInfo.id" class="user">
          
                <a class="nav-link" @click="out">登出</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>

    <div
      id="service"
      class="parallax first-section"
      style="background-image: url('img/p1img.jpg')"
    >
      <div class="container">
        <div class="row">
          <div
            class="app_iphone_01 wow slideInUp hidden-xs hidden-sm"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img src="img/app_iphone_01.png" alt="" class="img-fluid" />
          </div>
          <div
            class="app_iphone_02 wow slideInUp hidden-xs hidden-sm"
            data-wow-duration="1s"
            data-wow-delay="0.5s"
          >
            <img src="img/app_iphone_02.png" alt="" class="img-fluid" />
          </div>
          <div class="col-md-6 col-sm-12">
            <div class="big-tagline" align="center">
              <h2 align="center">SUPer Card</h2>
              <p class="lead">一個帳戶 體驗文化閱讀生活</p>
              <p class="lead2">
                您只需要一個SUPer Card帳戶，即可登入「一本MyBookOne」、「新雅書迷會」、「三聯讀書會」、「Bookaholic」及其他合作商或服務平台，享受服務、優惠或文化資訊。
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end section -->

    <div
      id="plan"
      class="parallax first-section"
      style="background-image: url('img/p5img.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-6">
            <div class="about-left">
              <img src="img/img05.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-lg-5 col-md-6" align="center">
            <div class="about-right">
              <h2>SUPer Card <br />會員計劃</h2>
              <p class="lead2">
               SUPer Card旗下的合作商或服務平台，均為您提供不同種類的服務，例如文化閱讀資訊、購物折扣、積分獎賞或優惠。
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end section -->

    <div
      id="offer"
      class="parallax first-section"
      style="background-image: url('img/p6img.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-5 col-md-6" align="center">
            <div class="about-right">
              <h2>兌換優惠</h2>
              <br /><img src="img/img6a.png" />
              <p class="lead2">
                SUPer Card旗下的部分服務平台提供積分獎賞計劃，會員於服務平台消費可賺取積分，兌換不同的獎賞。
              </p>
              <img src="img/img6b.png" />
              <p class="lead2">
                利用手機登入不同的服務平台，時刻緊貼優惠資訊。
              </p>
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end section -->

    <div
      id="shop"
      class="parallax first-section"
      style="background-image: url('img/p2img.jpg')"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-6">
            <div class="about-left">
              <img src="img/img03.png" class="img-fluid" alt="" />
            </div>
          </div>
          <div class="col-lg-5 col-md-6" align="center">
            <div class="about-right">
              <h2>線上線下</br>同步服務</h2>
              <p class="lead2">
               部分服務平台串連線下門市，為您提供更全面的服務，例如線上買書、線下取貨；線下消費賺取積分、兌換線上折扣券。
              </p>
              <!-- <p class="lead2">
                成為SUPer Card會員，<br />可登入「一本 MyBookOne」文化閱讀<br />購物平台，線上買書，線下取貨。
              </p>
              <p class="lead2">
                接收「三聯讀書會」、「Bookaholic」<br />及其他出版社的新書及文化活動通訊。
              </p> -->
            </div>
          </div>
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </div>
    <!-- end section -->

    <div
      id="partner"
      class="parallax first-section"
      style="background-image: url('img/p4img.jpg')"
    >
      <div class="container">
        <div class="section-title text-center">
          <h2>SUPer Card</h2>
          <p class="lead">現有合作商戶 / 現有服務平台</p>

          <p class="lead2">
            「一本 MyBookOne」 文化閱讀購物平台<br />
            「新雅書迷會」 兒童閱讀及教育綜合平台<br />
            「三聯讀書會」<br />
            「Bookaholic」
          </p>
          <!--		<img src="img/img-app-store.png">&nbsp;&nbsp;<img src="img/img-google-play.png"> -->
        </div>
        <!-- end title -->
      </div>
      <!-- end container -->
    </div>
    <!-- end section -->

    <div id="contact"></div>
    <footer class="footer">
      <div class="container">
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
            <div class="widget clearfix">
              <div class="widget-title">
                <img src="img/logo2.png" alt="" height="120" />
              </div>
              <p>提供不同文化閱讀資訊、購物折扣、獎賞及優惠。</p>
            </div>
            <!-- end clearfix -->
          </div>
          <!-- end col -->

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div class="widget clearfix">
              <div class="widget-title">
                <h3>條款及細則</h3>
              </div>
              <ul class="footer-links">
                <li><a href="#/termsandconditions">條款及細則</a></li>
                <li><a href="#/privacyandpolicy">私隱條款聲明</a></li>
              </ul>
              <!-- end links -->
            </div>
            <!-- end clearfix -->
          </div>
          <!-- end col -->

          <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
            <div class="widget clearfix">
              <div class="widget-title">
                <h3>聯絡我們</h3>
              </div>
              <ul class="footer-links">
                <li>
                  <a href="mailto:cs@supercard.com.hk">cs@supercard.com.hk</a>
                </li>
                <li>
                  <a href="http://www.supercard.com.hk">www.supercard.com.hk</a>
                </li>
              </ul>
              <!-- end links -->
            </div>
            <!-- end clearfix -->
          </div>
          <!-- end col -->
        </div>
        <!-- end row -->
      </div>
      <!-- end container -->
    </footer>
    <!-- end footer -->

    <div class="copyrights">
      <div class="container">
        <div class="footer-distributed">
          <div class="footer-left">
            <p class="footer-company-name">
              Copyright © SUP Retail (Hong Kong) Limited &nbsp;&nbsp; All Rights
              Reserved. <br />版權全屬 聯合新零售(香港)有限公司
            </p>
          </div>
        </div>
      </div>
      <!-- end container -->
    </div>
    <!-- end copyrights -->

    <a href="#top" data-scroll class="dmtop global-radius"
      ><i class="fa fa-angle-up"></i
    ></a>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
  data() {
    return {};
  },
  computed: {
    userInfo: function () {
      return this.$store.state.appData.userInfo;
    },
    ssoSDK: function () {
      return this.$store.state.appData.ssoSDK;
    },
  },
  watch: {
    ssoSDK: {
      handler(o, n) {
        if (this.ssoSDK) {
          this.getSSO();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    getSSO() {
      oauthSDK
        .getUserInfo()
        .then((e) => {
          this.$store.commit("userInfo", e);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    goToUserInfo() {
      oauthSDK.goToPage("userData");
    },
    custormanchor(id) {
      let anchorElement = document.getElementById(id);
      if (anchorElement) {
        anchorElement.scrollIntoView();
      }
    },
    out() {
      oauthSDK
        .ssoLogout({ membersession: this.$Cookies.get("memberSessionSSO") })
        .then(() => {
          location.reload();
        })
        .catch((err) => {});
    },
  },
  mounted() {
    console.log();
    this.$nextTick(() => {
      setTimeout(() => {
        if (this.$route.query && this.$route.query.show) {
          this.custormanchor(this.$route.query.show);
        }
      }, 500);
    });
    $("#default").zenith({
      layout: "default",
      slideSpeed: 450,
      autoplaySpeed: 2000,
    });
  },
};
</script>
<style scoped>
.user {
  display: flex;
  align-items: center;
}
.user_img {
  height: 30px;
  width: 30px;
  border-radius: 60px;
  margin-right: 5px;
}
.nickname {
}
.navbar-nav {
    color: #1f1f1f !important;
}
</style>
