<template>
  <div>
    <header>
      <nav class="navbar header-nav navbar-expand-lg">
        <div class="container">
          <a class="navbar-brand" href="index.html"
            ><img src="img/logo.png" height="100" alt="image"
          /></a>
          <button
            class="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarApp"
            aria-controls="navbarApp"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <div
            class="collapse navbar-collapse justify-content-end"
            id="navbarApp"
          >
            <ul class="navbar-nav">
              <li>
                <a class="nav-link active" href="#/home?show=service"
                  >SUPer Card服務</a
                >
              </li>
              <li><a class="nav-link" href="#/home?show=plan">會員計劃</a></li>
              <li><a class="nav-link" href="#/home?show=offer">優惠</a></li>
              <li><a class="nav-link" href="#/home?show=shop">線上線下服務</a></li>
              <li>
                <a class="nav-link" href="#/home?show=partner">合作商戶</a>
              </li>
              <li>
                <a class="nav-link" href="#/home?show=contact">聯絡我們</a>
              </li>
              <li id="sso-button" v-if="!userInfo.id">
                <a class="nav-link">登入</a>
              </li>
              <li v-if="userInfo.id">
                <a class="nav-link" @click="goToUserInfo">資料管理</a>
              </li>
              <li v-if="userInfo.id" class="user nav-link">
                      <img :src="userInfo.headimgurl" class="user_img" v-if="userInfo.headimgurl" />
                <img src="@/assets/user.png" class="user_img" v-if="!userInfo.headimgurl" />
                <div class="nickname">{{ userInfo.nickname }}</div>
              </li>
              <li v-if="userInfo.id" class="user">
                <a class="nav-link" @click="out">登出</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
    <template v-if="show">
      <div id="testimonials" class="section wb">
        <div class="container">
          <div class="section-title text-left">
            <h3>SUPer Card會員計劃 ─ 私隱條款聲明</h3>
            <h2>
              聯合新零售(香港)有限公司
              (以下簡稱「本公司」)尊重使用者的私隱權，並承諾遵守《個人資料(私隱)條例》的規定，盡力保障本公司所持有的個人資料的私隱，保密性及安全。本公司同樣承諾確保本公司所有員工及代理堅守這些責任。請詳細閱讀本條款以了解「SUPer
              Card會員計劃」收集、應用及保護閣下所提供的資料詳情及守則。當使用者瀏覽此網站或使用相關服務，代表使用者接受及同意此私隱條款。本條款可能會隨時變更，請定期查閱。
            </h2>
          </div>
          <!-- end title -->

          <div class="content">
            <h2>1) 個人資料收集及使用</h2>
            <p>
              1.1 當註冊「SUPer
              Card會員計劃」而成為會員時，需要使用者自願提供個人資料，包括但不限於個人姓名、性別、稱謂、出生年份/月份、聯絡手提電話號碼、電子郵件帳號、頭像圖片、個人興趣、電腦或移動裝置IP地址、即時位置資料、瀏覽器設定、瀏覽紀錄及／或其他互聯網記錄的資料等。
            </p>
            <p>
              1.2
              於提供某些服務時(如：網上購物)，服務要求使用者提供信用卡資料、聯絡地址或其他特定資料。
            </p>
            <p>
              1.3 當使用者使用「SUPer
              Card會員計劃」會員登入及使用列明合作商戶之列明服務時，代表該會員接受該合作商戶的條款及成為該服務會員，該會員資訊將同時錄入該合作商戶內。
            </p>
            <p>
              1.4 「SUPer
              Card會員計劃」及已獲使用者同意使用的列明合作商戶之列明服務內，可能使用不同的技術，如網路協定位址及小型文字檔案（cookies）記錄，在無須由使用者下主動提供的情況下，收集使用相關服務的數據，如訪問相關服務紀錄及網路地址(IP
              Address)，以收集用戶感興趣內容，用作統計及改善服務。
            </p>
            <p>
              1.5
              本公司綜合收集使用者的使用模式等資料，經整合及分析後會用以改善「SUPer
              Card」的易用性及提供的產品、服務、內容、廣告，但不會收集任何足以辨識使用者身分的資料。
            </p>
            <p>
              1.6
              使用者所提供之個人資料若有錯誤、不正確事情者，亦不受本聲明之保護。
            </p>

            <h2>2) 個人資料使用</h2>
            <p>
              「SUPer
              Card會員計劃」可能會從使用者收集所得的個人資料作以下的用途（包括但不限於）：
            </p>
            <p>
              2.1
              申請會藉、核實使用者身份、獲取積分及優惠、累計消費金額及積分、購物付費、送貨、參加活動等用途。
            </p>
            <p>2.2 處理使用者的訂單。</p>
            <p>2.3 為使用者提供服務。</p>
            <p>
              2.4 由「SUPer
              Card會員計劃」或合作商戶（如「一本」）向使用者發出會員電郵通訊，以提供會員服務。
            </p>
            <p>2.5 處理會員資料庫的日常運作。</p>
            <p>
              2.6 進行有關客戶、產品和服務的調查、用戶問卷、數據分析和活動。
            </p>
            <p>2.7 進行市場研究、統計分析及行為分析。</p>
            <p>2.8 處理及跟進服務要求、查詢及投訴。</p>
            <p>2.9 處理對本公司或任何⼀方之索償及／或訴訟。</p>
            <p>
              2.10 評估和進一步加強「SUPer
              Card」及聯合新零售(香港)有限公司的產品和服務。
            </p>
            <p>2.11 審計目的。</p>
            <p>2.12 防範及阻止欺詐。</p>
            <p>2.13 根據適用於本公司的法例要求而作出披露及。</p>
            <p>2.14 任何與收集個人資料的原來目的直接有關之其他用途。</p>

            <h2>3) 個人資料安全</h2>
            <p>
              3.1 在資訊交換的過程中，本公司提供「SUPer
              Card會員計劃」一個保險的伺服器，並以先進的安全編碼技術(“The Secure
              Sockets Layer (SSL) ”
              )將客戶所輸入的資料加密，從而努力保護使用者資訊的安全性。
            </p>
            <p>
              3.2
              使用者的登入名稱、會員帳號、密碼等任何個人資料，請不要提供給任何第三者。為客戶服務目的，員工需要時會使用以上資料進行身份核實，員工不會向使用者要求取得登入密碼及個人資料訊息。
            </p>
            <p>
              3.3
              使用者會員帳戶資料和網絡身份檔案資料均有密碼保護，只有使用者才能讀取此個人資料。
            </p>
            <p>
              3.4
              使用者可以利用登入電話號碼、電子郵件帳號和密碼，來修改會員資料和網絡身份檔案等資料。
            </p>
            <p>
              3.5
              基於網絡特性，所有使用者以登入電話、電郵和密碼存取或進入本系統後的行為，都視作使用者的行為。
            </p>
            <p>
              3.6 於「SUPer
              Card會員計劃」完成收到使用者傳送的資料後，本公司會盡最大努力確保儲存於具安全性的系統。由於網際網絡資料的傳輸不能保證百分之百的安全，儘管本公司努力保護使用者的個人資料安全，仍無法確保傳送或接收資料的安全。此風險並不在本公司承擔的責任範圍內。
            </p>

            <h2>4) 披露使用者之個人資料予第三方</h2>
            <p>
              4.1 使用者提供之個人資料將會被保密，「SUPer
              Card會員計劃」只會將收集之會員個人資料及用戶數據用於「SUPer
              Card會員計劃」或獲使用者同意使用的列明合作商戶之列明服務內。「SUPer
              Card會員計劃」絕不會把資料以任何形式出售、租借或轉讓予任何人士或組織。
            </p>
            <p>
              4.2
              若需要按法例的規定履行法律責任，或為使用者提供服務，或執行收集個⼈資料的原來目的或直接有關之⽬的，本公司有可能會向第三方披露使用者資料（不論其身處香港或香港境外），包括：
            </p>
            <p>
              (i)
              具管轄權的法院、執法機關、其他政府、法定或監管部⾨、機構或組織；
            </p>
            <p>
              (ii)
              所有本公司基於法律要求或按執法機構或其他政府部門及監管機關的要求而須將該等資料予以披露的相關人士；
            </p>
            <p>
              (iii)
              本公司之任何附屬公司、控股公司、關聯公司或與本公司同屬一家控制公司的公司或聯營公司；
            </p>
            <p>(iv) 本公司之合作商戶或服務供應商；</p>
            <p>
              (v)
              向本公司提供服務或意見的工作人員、承辦商、顧問、會計師、律師、服務提供者或其他執行資料保安工作的人士及機構等提供以維持必要運作；
            </p>
            <p>(vi) 銀行、金融機構、保險公司或信用卡發行公司。</p>

            <h2>5) 使用個人資料於直接促銷用途</h2>
            <p>
              5.1
              本公司有意使用使用者的個人資料作直接促銷，但本公司必須先取得使用者允許方可使用作該目的。在使用者的允許下，本公司或會將使用者的[稱謂、姓名、性別、出生日期、聯絡地址及電郵地址]用於以下用途：
            </p>
            <p>
              (i)
              直接促銷本公司的產品及服務[及本公司的業務夥伴或合作商戶的產品及服務（包括但不限於圖書、教育、玩具、環境、健康、媒體及娛樂、電訊、零售、休閒及體育、食物及飲料、交通及旅遊、電⼦電器和財務產品等）；
            </p>
            <p>
              (ii) 以郵寄方式或由「SUPer
              Card會員計劃」或合作商戶向使用者發出會員電郵通訊以提供本公司、本公司集團或合作商戶的優惠、獎賞及推廣資訊。
            </p>
            <p>
              5.2
              如果使用者不希望其個人資料用作直接促銷用途，請以書面或電郵告知。在收到使用者的通知後，本公司將停止使用該等資料作直接促銷之用，並不會就此向使用者收費。
            </p>

            <h2>6) 個人資料保障範圍</h2>
            <p>
              6.1 使用者必須明白本私隱政策只適用於「SUPer
              Card會員計劃」或獲使用者同意使用的列明合作商戶之列明服務運作的內容。本公司及合作商戶不會對其他連結本網站的第三方所提供的產品、服務、內容，以及有關私隱政策負責，亦不會因使用者經過相關服務傳送給第三方的個人資料負責，使用者應細閱該等平台或連結的私隱政策，以保障及承擔個人資料之安全。
            </p>

            <h2>7) 免責聲明</h2>
            <p>
              7.1 「SUPer
              Card會員計劃」及其合作商戶會採取適當保安措施保障閣下的個人資料不被不當使用、遺失、披露或未經授權存取，但不會就任何其控制範圍之外所發生的不當使用、遺失、披露或未經授權存取負責。同時，使用者亦應該採取適當措施確保用戶名稱和密碼不被外洩。
            </p>

            <h2>8) 個人資料的保存</h2>
            <p>
              8.1
              除非法律規定要求本公司須保存閣下的個人資料⼀段特定的時間，本公司只會將個人資料保存至達到收集個人資料之原來目的，或直接與其有關之目的為止，並會隨後銷毀該等資料。
            </p>

            <h2>9) 更改資料及查詢</h2>
            <p>
              9.1
              在此私隱權保護政策聲明下，使用者有權追究個人資料的用途、要求擁有使用者個人資料的副本、查閱個人資料及更改任何不準確的資料。如需查詢及更改個人資料，請以書面通知：
            </p>
            <p>致：客戶服務主任</p>
            <p>地址：香港鰂魚涌英皇道1065號東達中心1304至1306室</p>
            <p>電話：（852）29635331</p>
            <p>電郵通知︰cs@supercard.com.hk</p>
            <p>
              9.2 於處理索取資料的要求時，或會收取小額費用以彌補有關的行政開支。
            </p>

            <h3>此版本最後更新日期：2020年3月28日</h3>
          </div>
        </div>
      </div>

      <footer class="footer">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <img src="img/logo2.png" alt="" height="100" />
                </div>
                <p>提供不同文化閱讀資訊、購物折扣、獎賞及優惠。</p>
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <h3>條款及細則</h3>
                </div>
                <ul class="footer-links">
                  <li><a href="#/termsandconditions">條款及細則</a></li>
                  <li><a href="#/privacyandpolicy">私隱條款聲明</a></li>
                </ul>
                <!-- end links -->
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->

            <div class="col-lg-3 col-md-6 col-sm-12 col-xs-12">
              <div class="widget clearfix">
                <div class="widget-title">
                  <h3>聯絡我們</h3>
                </div>
                <ul class="footer-links">
                  <li>
                    <a href="mailto:cs@supercard.com.hk">cs@supercard.com.hk</a>
                  </li>
                  <li>
                    <a href="http://www.supercard.com.hk"
                      >www.supercard.com.hk</a
                    >
                  </li>
                </ul>
                <!-- end links -->
              </div>
              <!-- end clearfix -->
            </div>
            <!-- end col -->
          </div>
          <!-- end row -->
        </div>
        <!-- end container -->
      </footer>
      <!-- end footer -->

      <div class="copyrights">
        <div class="container">
          <div class="footer-distributed">
            <div class="footer-left">
              <p class="footer-company-name">
                Copyright © SUP Retail (Hong Kong) Limited &nbsp;&nbsp; All
                Rights Reserved. <br />版權全屬 聯合新零售(香港)有限公司
              </p>
            </div>
          </div>
        </div>
        <!-- end container -->
      </div>
      <!-- end copyrights -->
    </template>
    <a href="#top" data-scroll class="dmtop global-radius"
      ><i class="fa fa-angle-up"></i
    ></a>
  </div>
</template>
<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  computed: {
    userInfo: function () {
      return this.$store.state.appData.userInfo;
    },
  },
  methods: {
    out() {
      oauthSDK
        .ssoLogout({ membersession: this.$Cookies.get("memberSessionSSO") })
        .then(() => {
          location.reload();
        })
        .catch((err) => {});
    },
    goToUserInfo() {
      oauthSDK.goToPage("userData");
    },
  },
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.show = true;
      }, 100);
    });
    $("#default").zenith({
      layout: "default",
      slideSpeed: 450,
      autoplaySpeed: 2000,
    });
  },
};
</script>
<style scoped>
.user {
  display: flex;
  align-items: center;
}
.user_img {
  height: 30px;
  width: 30px;
  border-radius: 60px;
  margin-right: 5px;
}
.nickname {
}.navbar-nav {
    color: #1f1f1f !important;
}
</style>
