<template>
  <div></div>
</template>
<script>
export default {
  computed: {
    ssoSDK: function () {
      return this.$store.state.appData.ssoSDK;
    },
  },
  watch: {
    ssoSDK: {
      handler(o, n) {
        if (this.ssoSDK) {
          let meSe = this.$route.query.meSe;
          if (meSe) {
            this.getSSO(meSe);
          } else {
            oauthSDK.goToPage("login");
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},

  methods: {
    getSSO(memberSession) {
      try {
        this.$Cookies.set(
          "memberSessionSSO",
          memberSession,
          new Date().getTime() + 86400000,
          "/",
          process.env.VUE_APP_COOKIES_URL
        );
        oauthSDK
          .getUserInfo()
          .then((e) => {
            this.$store.commit("userInfo", e);
            this.$router.push("home");
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error)
      }
    },
  },
};
</script> 